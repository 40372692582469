<template>
  <div class="footer">
    <div class="block">
      <el-avatar :size="50" :src="circleUrl"></el-avatar>
    </div>
    <P>医院地址：西藏自治区拉萨市城关区江苏东路8号拉萨维多利亚整形美容医院</P>
    <P>CopyRight ©2022 | 拉萨维多利亚整形美容医院有限公司 | lswdly All
      Rights Reserved  ICP备案：
      <el-link target="_blank"     href="https://beian.miit.gov.cn/"  >  {{beian}}</el-link>

        医疗广审编号：{{gsh}}
    </P>
  </div>
</template>

<script>
export default {
  name: "ContainerFooter",
  data() {
    return {
      circleUrl: require("@/assets/images/foot_logo.png"),
      beian:"藏ICP备16000129号-2",
      gsh:"藏医广【2022】第11-25-21号"
    };
  },
};
</script>

<style>
</style>
